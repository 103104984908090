<template>
  <div class="environemnt-overview">
    <div class="env-component-list__controls">
      <v-text-field
        v-model="search"
        hide-details
        class="search-field"
        :placeholder="$t('searchComponents')">
        <template #prepend-inner>
          <v-icon
            class="search-field__icon"
            size="20px">
            search
          </v-icon>
        </template>
      </v-text-field>
      <div class="d-flex align-center space-x-2">
        <CyButtonToggle
          v-model="componentListDisplayMode"
          :items="$static.displayModes"
          active-theme="secondary"/>
        <CyButton
          elevation="0"
          icon="add_circle_outline"
          @click="redirectToNewComponent">
          {{ $t('environment.component.add') }}
        </CyButton>
      </div>
    </div>
    <div
      v-if="_.isEmpty(filteredEnvComponents) && search"
      class="env-component-list--empty">
      {{ $t('noMatchingComponents') }}
    </div>
    <CyEnvComponentList
      v-else
      :env-canonical="envCanonical"
      :project-canonical="projectCanonical"
      :env-components="filteredEnvComponents"
      :display-mode="componentListDisplayMode"
      @delete="(envComponent) => { envComponentToDelete = envComponent }"/>
    <CyModal
      v-if="envComponentToDelete"
      :header-title="$t('environment.component.delete')"
      :loading="deletingEnvComponent"
      :action-btn-func="onDeleteComponent"
      :cancel-btn-func="onCancelButtonClick"
      :action-btn-text="$t('forms.btnDelete')"
      keep-open-on-action-click
      modal-type="delete"
      small>
      <div class="pb-5">
        <p class="font-weight-bold">
          {{ $t('environment.component.deleteInfo') }}
        </p>
        <div class="d-flex align-center">
          <p class="ma-0 mr-2">
            {{ $t('environment.component.deleteConfirm') }}
          </p>
          <span class="d-flex align-center">
            <CyStackAvatar
              :stack="envComponentToDelete.stack"
              :size="24"/>
            <div class="font-size-base">{{ envComponentToDelete.name }}</div>
          </span>
        </div>
      </div>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'
import CyEnvComponentList from '@/components/CyEnvComponentList.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageEnvironmentOverview',
  components: {
    CyButtonToggle,
    CyEnvComponentList,
    CyStackAvatar,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
    envCanonical: {
      type: String,
      required: true,
    },
  },
  data: ({ projectCanonical }) => ({
    componentListDisplayMode: localStorage.getItem(`componentListDisplayMode:${projectCanonical}`) || 'card',
    search: '',
    envComponentToDelete: null,
    deletingEnvComponent: false,
  }),
  computed: {
    ...mapState('organization/project', {
      loadingEnvs: (state) => state.fetchInProgress.environments,
    }),
    ...mapGetters('organization/project', [
      'envComponents',
    ]),
    $static () {
      return {
        displayModes: [
          {
            key: 'card',
            value: 'card',
            icon: 'grid_view',
          },
          {
            key: 'list',
            value: 'list',
            icon: 'view_headline',
          },
        ],
      }
    },
    filteredEnvComponents () {
      return this.envComponents(this.envCanonical).filter((component) => {
        return component.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  watch: {
    componentListDisplayMode (newMode) {
      localStorage.setItem(`componentListDisplayMode:${this.projectCanonical}`, newMode)
    },
    envCanonical: {
      handler: 'checkEnvComponents',
      immediate: true,
    },
    loadingEnvs: {
      handler: 'checkEnvComponents',
      immediate: true,
    },
  },
  created () {
    this.GET_PROJECT_PIPELINES(this.projectCanonical)
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
      'DELETE_ENV_COMPONENT',
      'GET_PROJECT_ENVS',
    ]),
    redirectToNewComponent () {
      this.$router.push({ name: 'newEnvComponent' })
    },
    checkEnvComponents () {
      const { projectCanonical, envCanonical } = this
      const hasComponents = !_.isEmpty(this.envComponents(this.envCanonical))

      if (!hasComponents && !this.loadingEnvs) {
        this.$router.replace({
          name: 'environmentEmpty',
          params: { projectCanonical, envCanonical },
        })
      }
    },
    async onDeleteComponent () {
      this.deletingEnvComponent = true
      await this.DELETE_ENV_COMPONENT({
        envCanonical: this.envComponentToDelete.envCanonical,
        componentCanonical: this.envComponentToDelete.canonical,
      })
      await this.GET_PROJECT_ENVS()
      this.envComponentToDelete = null
      this.deletingEnvComponent = false
    },
    onCancelButtonClick () {
      this.envComponentToDelete = null
      this.deletingEnvComponent = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.environmentOverview',
        noMatchingComponents: 'None of the components in this environment matched your search',
        searchComponents: 'Search components',
      },
      es: {
        title: '@:routes.environmentOverview',
        noMatchingComponents: 'Ninguno de los componentes de este entorno coincide con su búsqueda',
        searchComponents: 'Buscar componentes',
      },
      fr: {
        title: '@:routes.environmentOverview',
        noMatchingComponents: 'Aucun des composants de cet environnement ne correspond à votre recherche',
        searchComponents: 'Rechercher des composants',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.env-component-list {
  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .search-field {
      max-width: 320px;
      height: 34px;
      margin-top: 0;
      padding-top: 0;
      border: 1px solid cy-get-color('primary', 'light-4');
      border-radius: 4px;
      background-color: cy-get-color('white');

      ::v-deep .v-input {
        &__slot {
          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }

      ::v-deep input {
        padding: 8px 0 10px;
        color: cy-get-color('primary', 'light-2');
        line-height: 1;

        &::placeholder {
          color: cy-get-color('primary', 'light-3');
          font-size: $font-size-default;
          line-height: 1;
        }
      }

      &__icon {
        margin-top: 2px;
        margin-left: 8px;
        color: cy-get-color('primary', 'light-3');
      }
    }

    ::v-deep  .v-btn.v-btn.cy-btn {
      height: 32px;
    }
  }

  &--empty {
    display: flex;
    justify-content: center;
    padding: 32px;
    color: cy-get-color('primary', 'light-1');
  }
}
</style>
